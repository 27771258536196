<template>
  <div id="app" class="artist">
    <Nav />
    <div class="artist-top mt50">
      <img v-if="$i18n.locale == 'zh'" src="@/assets/artist/artist-01.png" />
      <img v-else src="@/assets/artist/artist-02.png" />
      <div class="p20">
        <div class="boxText">
          <p v-html="$t('artistObj.level1')"></p>
        </div>
      </div>
    </div>
    <div class="artist-top">
      <img v-if="$i18n.locale == 'zh'" src="@/assets/artist/artist-03.png" />
      <img v-else src="@/assets/artist/artist-04.png" />
      <div class="p20">
        <div class="boxText">
          <p v-html="$t('artistObj.level2')"></p>
        </div>
      </div>
    </div>
    <!-- 乐手 -->
    <div class="pageMain-bottom" style="overflow: hidden">
      <div class="userMain">
        <div class="userMainTop">
          <img src="@/assets/click.png" width="11" />{{
            $t("bandsman.seeDetails")
          }}
        </div>
        <div class="userTool">
          <ul>
            <li
              v-for="(item, index) in list"
              :key="index"
              @click="listClick(index)"
              :class="{ active: item.active }"
            >
              <div class="userToolBlock">
                <span class="userToolBlockTop" v-if="$i18n.locale == 'zh'">{{
                  item.title
                }}</span>
                <span class="userToolBlockTop" v-if="$i18n.locale == 'zh' && !item.active">{{
                  item.zh
                }}</span>
                <span class="userToolBlockTop" v-if="$i18n.locale != 'zh'">{{ item.py }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="p20">
        <div class="userList" v-if="activeList.length">
          <div class="userListTitle">{{ timeEn }}</div>
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, key) in activeList" :key="key">
              <div class="userListBox">
                <div class="pic">
                  <img :src="`${$baseUrl1}${item.thumb}`" />
                  <!-- <span class="tips" v-if="item.post">{{ item.post }}</span> -->
                </div>
                <p style="position: relative" class="userListBox-p1">
                  {{ splitName(item) }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style scoped>
</style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      tag: "",
      timeEn: "",
      members: [],
      list: [
        {
          title: "打击乐",
          py: "Percussion",
          zh:"DaJiYue",
          active: false,
        },
        {
          title: "梆笛",
          py: "Bangdi",
          zh:"BangDi",
          active: false,
        },
        {
          title: "曲笛",
          py: "Qudi",
          zh:"QuDi",
          active: false,
        },
        {
          title: "新笛",
          py: "Xindi",
          zh:"XinDi",
          active: false,
        },
        {
          title: "低音唢呐",
          py: "Bass Suona",
          zh:"DiYinSuoNa",
          active: false,
        },
        {
          title: "中音唢呐",
          py: "Alto Suona",
          zh:"ZhongYinSuoNa",
          active: false,
        },
        {
          title: "高音唢呐",
          py: "Soprano Suona",
          zh:"GaoYinSuoNa",
          active: false,
        },
        {
          title: "倍低音笙",
          py: "Contrabass Sheng",
          zh:"BeiDiYinSuoNa",
          active: false,
        },
        {
          title: "中音笙",
          py: "Alto Sheng",
          zh:"ZhongYinSheng",
          active: false,
        },
        {
          title: "高音笙",
          py: "Soprano Sheng",
          zh:"GaoYinSheng",
          active: false,
        },
        {
          title: "次中音笙",
          py: "Tenor sheng",
          zh:"CiZhongYinSheng",
          active: false,
        },
        {
          title: "低音笙",
          py: "Bass Sheng",
          zh:"DiYinSheng",
          active: false,
        },
        {
          title: "古筝",
          py: "Guzheng",
          zh:"GuZheng",
          active: false,
        },
        {
          title: "扬琴",
          py: "Yangqin",
          zh:"YangQin",
          active: false,
        },
        {
          title: "琵琶",
          py: "Pipa",
          zh:"PiPa",
          active: false,
        },
        {
          title: "中阮",
          py: "Zhongruan",
          zh:"ZhongRuan",
          active: false,
        },
        {
          title: "大阮",
          py: "Daruan",
          zh:"DaRuan",
          active: false,
        },
        {
          title: "三弦",
          py: "Sanxian",
          zh:"SanXian",
          active: false,
        },
        {
          title: "竖琴",
          py: "Harp",
          zh:"ShuQin",
          active: false,
        },
        {
          title: "高胡",
          py: "Gaohu",
          zh:"GaoHu",
          active: false,
        },
        {
          title: "中胡",
          py: "Zhonghu",
          zh:"ZhongHu",
          active: false,
        },
        {
          title: "柳琴",
          py: "Liuqin",
          zh:"LiuQin",
          active: false,
        },
        {
          title: "大提琴",
          py: "Cello",
          zh:"DaTiQin",
          active: false,
        },
        {
          title: "低音提琴",
          py: "Double Bass",
          zh:"DiYinTiQin",
          active: false,
        },
        {
          title: "二胡",
          py: "Erhu",
          zh:"ErHu",
          active: false,
        },
      ],
    };
  },
  computed: {
    activeList() {
      // this.members
      let list = [];
      if (this.tag && this.members.length) {
        list = this.members.filter((item) => {
          return item.tags.indexOf(this.tag) > -1;
        });
      }
      return list;
    },
  },
  mounted() {
    this.getMembers();
  },
  methods: {
    listClick(index) {
      for (var i = 0; i < this.list.length; i++) {
        this.list[i].active = false;
      }
      this.list[index].active = true;
      this.timeEn = this.list[index].py;
      this.tag = this.list[index].title;
    },

    getMembers() {
      this.$ajax
        .get("https://api.sucoch.com/cms/cms/api/member", {})
        .then((res) => {
          if (res.data && res.data.length) {
            this.members = res.data;
          }
        });
    },

    splitName(item) {
      if (!item.description) return "";
      let tem = [];
      let tem2 = [];
      let string = "";
      tem = item.description.split("\n");
      tem2 = tem[0].split("（");
      if (tem2.length > 1) {
        tem2[1] = tem2[1].replace("）", "");
        if (this.$i18n.locale == "zh") {
          string = tem2[0];
        } else {
          string = tem[1];
        }
        // string = `<h3>${tem2[0]}</h3>
        // <h3 >${tem[1]}</h3>
        // `;
        // <div class="city">${tem2[1]}</div>
      } else {
        if (this.$i18n.locale == "zh") {
          string = tem[0];
        } else {
          if (item.id == "458838") {
            string = tem[0];
          } else {
            string = tem[1];
          }
        }
        // string = `<h3 >${tem[0]}</h3>
        // <h3>${tem[1]}</h3>
        // `;
      }

      return string;
    },
  },
};
</script>

<style scoped>
#app {
  background-color: #f2f6f7;
}
.artist-top {
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
}
</style>
<style lang="scss" scoped>
.artist {
  img {
    max-width: 100%;
  }
}
.userMain {
  background: url(../../../assets/p23.jpg) no-repeat top center / 100% auto;
}
.userListBox-p1 {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.userMainTop {
  text-align: center;
  font-size: 11px;
  font-weight: 200;
  padding: 10px 0 0 0;
}

.userMainTop img {
  vertical-align: middle;
  margin: -2px 3px 0 0;
}

.userTool {
  height: 350px;
  position: relative;
}

.userTool ul,
.userTool li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.userTool li {
  position: absolute;
  line-height: 12px;
  text-align: center;
  text-shadow: 0 0 12px #ca3e24;
  padding: 10px 0;
  width: 70px;
  z-index: 2;
}
.userTool .active {
  z-index: 5;
}

.userToolBlock {
  position: relative;
}

.userToolBlock::after {
  display: block;
  content: "";
  position: absolute;
  background: #b49971;
  border-radius: 100%;
  z-index: 4;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62px;
  height: 62px;
}

.userTool li.active {
  text-shadow: none;
}

.userTool li.active .userToolBlockBottom {
  color: #fff;
  padding-top: 5px;
  font-weight: bold;
}

.userTool li.active .userToolBlockTop {
  color: #fff;
  padding-top: 5px;
  font-weight: bold;
  z-index: 5;
}

.userTool li.active .userToolBlock::after {
  display: block;
}

.userToolBlockTop {
  display: block;
  position: relative;
  z-index: 2;
  font-size: 11px;
  letter-spacing: 0px;
}

.userToolBlockBottom {
  font-size: 11px;
  font-weight: 200;
  letter-spacing: 0px;
}

.userTool li:nth-child(1) {
  left: 41%;
  top: 5px;
}

.userTool li:nth-child(2) {
  left: 0;
  top: 100px;
}

.userTool li:nth-child(3) {
  left: 10%;
  top: 77px;
}

.userTool li:nth-child(4) {
  left: 22%;
  top: 57px;
}

.userTool li:nth-child(5) {
  left: 40%;
  top: 45px;
}

.userTool li:nth-child(6) {
  left: 60%;
  top: 58px;
}

.userTool li:nth-child(7) {
  right: 2%;
  top: 88px;
}

.userTool li:nth-child(8) {
  left: 3%;
  top: 145px;
}

.userTool li:nth-child(9) {
  left: 22%;
  top: 115px;
}

.userTool li:nth-child(10) {
  right: 40%;
  top: 96px;
}
.userTool li:nth-child(11) {
  right: 20%;
  top: 115px;
}
.userTool li:nth-child(12) {
  right: 3%;
  top: 145px;
}

.userTool li:nth-child(13) {
  left: 0;
  top: 203px;
  text-shadow: 0 0 12px #b2712d;
}

.userTool li:nth-child(14) {
  left: 13%;
  top: 181px;
  text-shadow: 0 0 12px #b2712d;
}

.userTool li:nth-child(15) {
  left: 25%;
  top: 164px;
  text-shadow: 0 0 12px #b2712d;
}

.userTool li:nth-child(16) {
  left: 40%;
  top: 149px;
  text-shadow: 0 0 12px #b2712d;
}

.userTool li:nth-child(17) {
  right: 25%;
  top: 163px;
  text-shadow: 0 0 12px #b2712d;
}

.userTool li:nth-child(18) {
  right: 13%;
  top: 181px;
  text-shadow: 0 0 12px #b2712d;
}

.userTool li:nth-child(19) {
  right: 0;
  top: 198px;
  text-shadow: 0 0 12px #b2712d;
}

.userTool li:nth-child(20) {
  left: 0;
  top: 250px;
  text-shadow: 0 0 12px #e37f14;
}

.userTool li:nth-child(21) {
  left: 18%;
  top: 225px;
  text-shadow: 0 0 12px #e37f14;
}

.userTool li:nth-child(22) {
  left: 33%;
  top: 205px;
  text-shadow: 0 0 12px #e37f14;
}

.userTool li:nth-child(23) {
  right: 33%;
  top: 205px;
  text-shadow: 0 0 12px #e37f14;
}

.userTool li:nth-child(24) {
  right: 15%;
  top: 225px;
  text-shadow: 0 0 12px #e37f14;
}

.userTool li:nth-child(25) {
  right: 0;
  top: 250px;
  text-shadow: 0 0 12px #e37f14;
}

.userList {
  background: #dadcdf;
  padding: 20px;
}

.userListBox {
  padding-bottom: 20px;
  display: block;
}

.userListBox h3 {
  font-size: 14px;
  margin: 0;
}

.userListBox h3 {
  color: #333;
  line-height: 130%;
  margin: 0;
  padding: 2px 0 0 0;
}

.userListBox small {
  display: block;
  font-weight: 300;
  color: #999;
  font-family: "Didot";
}

.userListBox .pic {
  position: relative;
  overflow: hidden;
}

.userListBox .pic .tips {
  font-size: 12px;
  height: 26px;
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  line-height: 26px;
  padding-left: 8px;
  /* padding-right: 20px; */
  text-align: left;
  background-image: linear-gradient(
    to right,
    rgba(179, 154, 113, 1) 50%,
    rgba(179, 154, 113, 0) 95%
  );
}
.pic img {
  height: 151px;
}
.userListBox .city {
  font-size: 11px;
  width: 24px;
  position: absolute;
  right: 0;
  top: 2px;
  line-height: 1;
}

.userListTitle {
  text-shadow: 0 0 1px #bbac94;
  color: #dadcdf;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  text-transform: Uppercase;
  margin: 0 0 10px 0;
  position: relative;
}

.userListTitle::after {
  content: "";
  border-top: 1px solid #333;
  border-left: 1px solid #333;
  cursor: pointer;
  width: 10px;
  height: 10px;
  display: block;
  left: 50%;
  top: 5px;
  position: absolute;
  -webkit-transition: 0.4s ease-in 0s;
  -o-transition: 0.4s ease-in 0s;
  transition: 0.4s ease-in 0s;
  transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  /* IE 9 */
  -moz-transform: rotate(-135deg);
  /* Firefox */
  -webkit-transform: rotate(-135deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(-135deg);
}
</style>

